import React from "react";
import { motion } from "framer-motion";
import "../styling/footer.scss";
import { Link } from "react-router-dom";
import Logo from "../media/psa-logo-white.svg";
import Whatsapp from "../media/whatsapp-white.svg";
import Phone from "../media/phone-white.svg";

const FooterHome = () => {
  return (
    <motion.div
      id="footer"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 1 }}
      className="footer"
    >
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-content-brand">
            <div className="footer-content-brand-logo">
              <Link to="/">
                <img className="footer-logo" src={Logo} alt={"logo"} />
              </Link>
              <div className="footer-content-brand-copyright">
                <small>
                  Comunidad de Propietarios de Pinares de San Antón © 2024{" "}
                </small>
                <small> - Todos los derechos reservados</small>
              </div>
            </div>
            <div className="footer-content-brand-cookies">
              <Link to="/cookies-y-privacidad">
                <p>Cookies y privacidad</p>
              </Link>
            </div>
          </div>
          <div className="footer-content-links">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/sobre-nosotros">Sobre Nosotros</Link>
              </li>
              <li>
                <Link to="/servicios">Servicios</Link>
              </li>
              <li>
                <Link to="/galeria">Galería</Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
              <li>
                <Link to="https://www.comunidadpsa.es/comunidad">
                  Zona Privada
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-content-contact">
            <div className="footer-content-contact-phone">
              <div className="footer-content-contact-item">
                <h4>Oficina de la Comunidad</h4>
                <a href="mailto:info@comunidadpsa.es">info@comunidadpsa.es</a>
              </div>
              <small>Horario de Lunes a Viernes de 8:00 a 14:00h.</small>
              <br></br>
              <br></br>
              <a href="tel:+34656915734">
                <img
                  className="footer-content-contact-item-icon"
                  src={Whatsapp}
                  alt="whatsapp"
                />
                656 915 734
              </a>
              <br></br>
              <a href="tel:+34952291149">
                <img
                  className="footer-content-contact-item-icon"
                  src={Phone}
                  alt="phone"
                />
                952 29 11 49
              </a>
            </div>
            <div className="footer-content-contact-emails">
              {/* <div className="footer-content-contact-item">
                <h4>Presidencia</h4>
                <a href="mailto:info@pinaresdesananton.net">
                presidencia@pinaresdesananton.net
                </a>
              </div> */}
              <div className="footer-content-contact-item">
                <h4>Administración</h4>
                <a href="mailto:info@pinaresdesananton.net">
                  asesores@franciscoleal.es
                </a>
                <br></br>
                <br></br>
                <a href="tel:+34951194034">
                  <img
                    className="footer-content-contact-item-icon"
                    src={Phone}
                    alt="phone"
                  />
                  951 19 40 34
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          {/* <div className="footer-bottom-line"></div> */}
          {/* <div className="footer-bottom-copyright">
            <small>
              Comunidad de Propietarios de Pinares de San Anton © 2024{" "}
            </small>
            <small> - Todos los derechos reservados</small>
          </div> */}
          <div className="footer-bottom-developed">
            <small>
              Desarrollado por{" "}
              <a href="http://www.mrlopez.io" target="_blank" rel="noreferrer">
                mrlopez.io
              </a>
            </small>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FooterHome;

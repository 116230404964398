import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../media/psa-logo.svg";
import IconMenu from "../media/icon-menu.svg";
import IconClose from "../media/icon-close.svg";
import "../styling/nav.scss";
import { motion } from "framer-motion";
import { X, Facebook, Instagram } from "./SocialIcons";

const navLinks = [
  { id: "/", path: "/", text: "Home" },
  { id: "/sobre-nosotros", path: "/sobre-nosotros", text: "Sobre nosotros" },
  { id: "/servicios", path: "/servicios", text: "Servicios" },
  { id: "/galeria", path: "/galeria", text: "Galería" },
  { id: "/contacto", path: "/contacto", text: "Contacto" },
  {
    id: "/zona-privada",
    path: "https://www.comunidadpsa.es/comunidad",
    text: "Zona privada",
  },
  {
    id: "",
    path: "https://www.instagram.com/comunidad_psa/",
    text: "",
    icon: <Instagram />,
  },
  {
    id: "",
    path: "https://www.facebook.com/profile.php?id=61572290502752",
    text: "",
    icon: <Facebook />,
  },
  {
    id: "",
    path: "https://x.com/ComunidadPsa",
    text: "",
    icon: <X />,
  },
];

const navLinkAnim = {
  open: (index) => ({
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.3 + 0.1 * index,
      duration: 0.65,
      ease: [0.76, 0, 0.24, 1],
    },
  }),
  close: (index) => ({
    x: 40,
    opacity: 0,
    transition: {
      delay: 0.4 - 0.1 * index,
      duration: 0.6,
      ease: [0.76, 0, 0.24, 1],
    },
  }),
};
const closeIconAnim = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: 0.75,
      ease: [0.76, 0, 0.24, 1],
    },
  },
  close: {
    x: "100%",
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};
const menuAnim = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: [0.76, 0, 0.24, 1],
    },
  },
  close: {
    x: "100%",
    opacity: 0,
    transition: {
      delay: 0.6,
      duration: 0.75,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};

const Nav = () => {
  const location = useLocation();
  // const [activeNav, setActiveNav] = useState();
  const [menuToggle, setMenuToggle] = useState(false);

  return (
    <div className="nav">
      <div className="wrapper">
        <Link to="/">
          <img className="nav-logo" src={Logo} alt={"logo"} />
        </Link>

        {/* Implement mobile menu here... */}

        <MobileNav
          menuToggle={menuToggle}
          setMenuToggle={setMenuToggle}
          navLinks={navLinks}
        />

        {!menuToggle && (
          <div className="nav-links">
            {navLinks.map((link, index) => {
              return (
                <React.Fragment key={`nav-${link.text}`}>
                  {link.id === "/zona-privada" ? (
                    <a href={link.path}>{link.text}</a>
                  ) : (
                    <>
                      <Link
                        to={link.path}
                        className={`nav-links-link ${
                          location.pathname === link.id ? "active-link" : ""
                        }`}
                        target={link.id === "" ? "_blank" : ""}
                      >
                        {link.text !== "" ? link.text : link.icon}
                        {location.pathname === link.id && (
                          <motion.div
                            layoutId="active-link"
                            className="nav-links-link-active"
                            // transition={{ type: "spring", damping: 15 }}
                          >
                            {/* {link.text} */}
                          </motion.div>
                        )}
                      </Link>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;

const MobileNav = ({ navLinks }) => {
  const [openMenu, setOpenMenu] = useState(false);

  // useEffect(() => {
  //   console.log("menuToggle", openMenu);
  // }, [openMenu]);

  return (
    <>
      <div className="nav-mobile-menu" onClick={() => setOpenMenu(true)}>
        <img className="nav-mobile-menu-icon" src={IconMenu} alt={"menu"} />
      </div>
      <motion.div
        variants={menuAnim}
        initial="close"
        animate={openMenu ? "open" : "close"}
        exit="close"
        // transition={{ duration: 0.5, ease: "anticipate" }}
        className="mobile-nav"
      >
        <motion.img
          variants={closeIconAnim}
          initial="initial"
          animate={openMenu ? "open" : "close"}
          exit="initial"
          className="mobile-nav-close-button"
          src={IconClose}
          alt={"logo"}
          onClick={() => setOpenMenu(false)}
        />

        {navLinks.map((link, index) => (
          <React.Fragment key={`nav-${link.text}${index}`}>
            <motion.span
              variants={navLinkAnim}
              initial="initial"
              animate={openMenu ? "open" : "close"}
              exit="initial"
              custom={index}
            >
              <Link
                to={link.path}
                className="nav-links-link"
                onClick={() => setOpenMenu(false)}
              >
                {link.text !== "" ? link.text : link.icon}
              </Link>
            </motion.span>
          </React.Fragment>
        ))}
      </motion.div>
    </>
  );
};
